body.sample-view {
  background: #fafafa;

  #main {
    background: #fff;
  }

  .sample-view {
    background: #fafafa;
    border-top: 1px solid $greyBorder;
  }

  .filters-holder {
    border-top: 0;
  }
}

.page-viewpage {
  .cards {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    .card {
      width: 32.5%;
    }
  }

  .no-results {
    text-align: center;
  }

  .actual-view {
    position: relative;
    padding: 2em 0;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .desc {
    margin-top: 0.25em;
    font-size: 14px;
    color: $grey;
  }

  @media (max-width: 1024px) {
    .cards .card {
      width: 49%;
    }
  }
}
