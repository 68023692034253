.react-autosuggest__container--open {
  .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  z-index: 99;
  top: 100%;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  background: #fff;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.02);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #f2f2f2;
  color: $bodyFontColor;

  .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .link {
    display: block;
    color: $bodyFontColor;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      padding: 0;
      margin: 0;
    }

    $resultLeftPadding: 25px;

    .depth-1 {
      .link {
        padding-left: $resultLeftPadding * 2;
      }
    }
    .depth-2 {
      .link {
        padding-left: $resultLeftPadding * 3;
      }
    }

    .highlight {
      font-weight: bold;
    }

    .link {
      display: block;
      padding: 10px $resultLeftPadding;
      color: $bodyFontColor;
      cursor: pointer; // TODO: remove once we have an actual link
      text-decoration: none;
      &:hover {
        background: darken($greyHover, 10%);
      }
    }
  }
}

.react-autosuggest__suggestion--highlighted {
  background: darken($greyHover, 5%);
}

.search-box {
  background: #fff;
  color: #111;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

.react-autosuggest__input {
  font-size: 100%;
  padding: 5px 10px;
  min-width: 50%;
}
