@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/alert";

// redux toastr
@import "~react-redux-toastr/src/styles/index";

@import url("https://fonts.googleapis.com/css?family=Mukta&display=swap");

// config
@import "./assets/sass/_variables";

// import dom elements
@import "./assets/sass/components/header";
@import "./assets/sass/components/footer";
@import "./assets/sass/components/btn";

// page styles
@import "./assets/sass/pages/viewpage";
@import "./assets/sass/pages/userpage";

// general styles
@import "./assets/sass/forms";

// actual components
@import "./assets/sass/components/breadcrumb";
@import "./assets/sass/components/card";
@import "./assets/sass/components/cart";
@import "./assets/sass/components/directory";
@import "./assets/sass/components/filters";
@import "./assets/sass/components/infomodalbutton";
@import "./assets/sass/components/searchbox";
@import "./assets/sass/components/simplebar";

// print stylesheet
@import "./assets/sass/print";

// common styles
@import "./assets/sass/common";
