@import "../../assets/sass/_variables";

.horizontalLine {
  width: 100%;
  height: 1px;
  line-height: 1px;
  border: 0;
  background: $greyBorder;
  margin-bottom: 20px;
  margin-top: 20px;
}
