/* The container */
.container-checkbox {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-left: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  top: 5px;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  border-color: #666;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: $blue;
  border-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="text"],
input[type="password"],
textarea {
  font-family: $bodyFontFamily;
}

button {
  font-family: $bodyFontFamily;
}

.form-group {
  .invalid-feedback {
    font-size: 90%;
  }

  .form-link {
    border: 0;
    background: transparent;
    font-family: inherit;
    text-decoration: underline;
    color: $blue;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
  }
}

form {
  h2 {
    margin-top: 0;
    font-size: 22px;
  }
}

.form-text {
  color: $grey;

  a {
    color: $grey;
  }
}

.form-extra {
  margin: 30px 0 0 0;
  text-align: center;
  color: $grey;

  a {
    color: $bodyFontColor;
  }

  p {
    margin: 0;
  }
}

input,
select {
  font-family: inherit;
}
