@import "../../../assets/sass/variables";

.listItem {
  cursor: pointer;
  display: flex;
  border-radius: 3px;
  border: solid 1px $greyBorder;
  margin-bottom: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;

  &:hover {
    background-color: $greyHover;
  }

  h2 {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    line-height: 1.3;
    color: $bodyFontColor;
  }

  .icon {
    margin-right: 33px;
  }

  .createdAt {
    font-size: 16px;
    line-height: 1.63;
    color: $grey;
    margin: 0;
  }

  .status {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 1.86;
    text-align: right;
    color: $grey;
  }

  .statusContainer {
    margin-left: auto;
  }

  .projectInfo {
    flex: 1;
  }

  .projectInfo {
    h2 {
      margin-bottom: 5px;
    }
  }

  .statusContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.listOptions {
  position: relative;
  margin-left: 20px;

  display: flex;
  align-items: center;

  ul {
    display: none;
  }

  img {
    display: block;
  }
}

.listOptionsDropdown {
  position: absolute;
  right: 0;
  top: 100%;
  list-style: none;
  min-width: 150px;
  margin: 5px 0 0 0;
  padding: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: $bodyFontColor;
  display: none;
  z-index: 2;

  > li {
    margin: 0;
    padding: 0;
  }

  a,
  button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    color: $bodyFontColor !important; // :(
  }
}

.listOptionsDropdownVisible {
  display: block !important;
}

.listOptionsDropdownButton {
  background: transparent;
  font-size: inherit;
  border: 0;
  height: 44px;

  img {
    height: 20px;
  }
}
