.simple-bar {
  width: 100%;

  .title {
    margin-bottom: 0.25em;
  }

  .bars {
    display: flex;
    justify-content: space-between;
    .bar {
      display: inline-block;
      min-width: 5px;
      height: 4px;
      border-radius: 5px;
      transition: width 0.6s ease-in-out;

      &.left {
        background: $blue;
        margin-right: 3px;
      }
      &.right {
        flex: 1;
        background: $lightGrey;
      }
    }
  }

  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25em;
    color: #444;
    font-size: 14px;
  }

  .label {
    text-transform: capitalize;
  }
}
