.keywordMultiSelect {
  ul:first-of-type {
    margin: 1px;
    background-color: #fff;

    li:last-of-type {
      > * {
        background-color: #fff;
      }

      div:last-of-type::before {
        width: 0.325em;
        height: 0.325em;
        top: 0.025em;
      }
    }
  }

  > div {
    > div {
      > div {
        border-radius: 0.25rem;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        background-color: transparent;
      }
    }
  }
}
