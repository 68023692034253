@import "../../assets/sass/variables";

.infoBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.icon {
  margin-bottom: 40px;
}

.text {
  text-align: center;
  max-width: 504px;
  color: $grey;
}
