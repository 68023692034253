.page-user {
  .finbb-container {
    width: fit-content;
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p,
    div {
      width: fit-content;
      max-width: 450px;
      text-align: center;
    }
  }
}
