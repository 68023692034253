@import "../../assets/sass/variables";

.commentResponse {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.63;
  color: $grey;
}

.response {
  margin: 0;
  margin-bottom: 96px;
  font-size: 16px;
  line-height: 1.63;
  color: $grey;
}

.responseTitle {
  font-size: 24px;
  margin-top: 2em;
}

.answerBlock {
  display: flex;
  margin-bottom: 10px;
}

.biobankName {
  flex: 1;
}

.biobankTitle {
  font-size: 16px;
  color: $bodyFontColor;
}

.value {
  font-size: 16px;
  text-align: right;
  color: $bodyFontColor;
}

.total {
  font-size: 20px;
  text-align: right;
  color: $bodyFontColor;
  align-self: flex-end;
}

.sectionTitle {
  font-size: 20px;
  margin: 0 0 2em 0;
}

.tableTitle {
  font-size: 22px;
}
