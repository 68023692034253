@import "../../assets/sass/variables";

.requestTypeContainer {
  min-height: 453px;
}

.requestTypeBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  border: solid 1px $greyBorder;
  padding: 90px;
  margin-bottom: 20px;

  &:not(:first-child) {
    margin-left: 15px;
  }
}

.requestTitle {
  font-size: 20px;
  line-height: 1.3;
  margin-top: 36px;
}

.requestDescription {
  font-size: 16px;
  line-height: 1.63;
  text-align: center;
  color: $darkGrey;
  margin-bottom: 40px;
}

.recommendedText {
  margin-top: 10px;
  color: $darkGrey;
  font-size: 90%;
}

.requestTypeBlockContainer {
  display: flex;

  &:first-child {
    .requestTypeBlock {
      background: $greyHover;
    }
  }
}
