@import "../../assets/sass/_variables";

.navigationUserContainer {
  position: relative;
  display: inline-block;
}

.navigationUser {
  cursor: pointer;

  &:after {
    content: "";
    margin-left: 5px;
    width: 10px;
    height: 6px;
    display: inline-block;
    background: url(../../assets/img/chevron-down.svg) no-repeat 0 0;
  }
}

.navigationUserDropdown {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  list-style: none;
  width: 179px;
  margin: 5px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: $bodyFontColor;
  padding: 10px;
  font-size: 16px;

  display: none;

  > li {
    margin: 0;
    padding: 0 5px;
  }

  a {
    color: $bodyFontColor !important; // :(
  }
}

.navigationUserDropdownVisible {
  display: block;
}
