@import "./_variables";

:root {
  --body-font-family: #{$bodyFontFamily};
  --body-font-color: #{$bodyFontColor};
  --color-blue: #{$blue};
  --color-dark-blue: #{$darkBlue};
  --color-darker-blue: #{$darkerBlue};
  --color-red: #{$red};
  --color-grey-hover: #{$greyHover};
  --color-grey-border: #{$greyBorder};
  --color-grey: #{$grey};
  --color-dark-grey: #{$darkGrey};
  --color-light-grey: #{$lightGrey};
  --max-width: #{$maxWidth};
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-family: $bodyFontFamily;
  font-size: 100%;
  line-height: 180%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main {
  padding-top: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.finbb-container {
  min-width: 768px;
  max-width: $maxWidth;
  width: 100%;
  padding: 0 1em;
  margin: 0 auto;
}

.container {
  max-width: $maxWidth;
}

.custom-loader {
  width: 100%;
  height: auto;
}

.sample-view-content {
  //display: flex;

  .filters {
    //width: 25%;
    display: flex;
  }
}

.sample-view-content .sample-view-content .graphs {
  flex: 1;
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

input,
button,
select,
optgroup,
textarea {
  font-size: inherit;
  line-height: inherit;
}

/* hack in order to make the footer stick to the bottom */
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

.App {
  padding-bottom: 4em;
}

.left-margin {
  margin-left: 20px;
}

.section-description {
  color: $grey;
}

.top-margin {
  margin-top: 20px;
}

.reset-top-margin {
  margin-top: 0;
}

.double-vertical-margin {
  margin: 40px 0;
}

.text-center {
  text-align: center;
}

.double-vertical-margin-bottom {
  margin: 0 0 40px 0;
}

.mta-footer {
  margin: 40px 0;
  div {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }
}

.mta-logo {
  max-width: 350px;
}

// Toastr messages top-center
.redux-toastr {
  .top-center {
    width: auto;
    max-width: 90vw;
    margin: 0;
    transform: translate(-50%, 15%);
  }
}

.hidden {
  display: none;
}

.margin-top-none {
  margin-top: 0;
}

.margin-top-sm {
  margin-top: 0.5rem;
}

.margin-top-md {
  margin-top: 1.5rem;
}

.sub-section-description {
  color: $grey;
  font-size: 12px;
}
