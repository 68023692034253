.filters-holder {
  background: #fff;
  border-top: 1px solid $greyBorder;
  border-bottom: 1px solid $greyBorder;
}

.filters {
  display: flex;
  padding: 1em 0em 1.5em 0em;
  justify-content: space-between;

  .filter-group {
    width: 32%;
  }

  ul {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;

    &.disabled * {
      opacity: 0.2;
    }
  }

  li {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 5em;
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: 90%;
    color: $darkGrey;

    .info-btn {
      margin-left: 0.5em;
    }
  }

  .label {
    max-width: 75%;
  }
}
