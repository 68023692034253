.breadcrumb {
  margin: 1em 0;
  color: $grey;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      display: inline;
      &:after {
        content: " / ";
      }
    }
  }
  h2 {
    margin-top: 0.3em;
    margin-bottom: 1.5em;
    font-weight: normal;
    color: $bodyFontColor;
    font-size: 26px;
  }

  a {
    text-decoration: none;
    color: $grey;
  }
}
