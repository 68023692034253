$bodyFontFamily: Mukta, sans-serif;
$bodyFontColor: #000;

$blue: #113686;
$darkBlue: #143386;
$darkerBlue: #0e276c;

$red: #e02828;

$greyHover: #f9f9f9;
$grey: #a2a2a2;
$greyBorder: #dce0e0;
$darkGrey: #707070;
$lightGrey: #e3e3e3;

$maxWidth: 1280px;
