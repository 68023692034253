.card {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #efefef;
  padding: 1.5em 1.75em 2em 1.75em;
  margin: 1em 0;

  &.card-disabled {
    opacity: 0.5;
  }

  &.fake {
    border: 0;
    padding: 0;
    background: transparent;
  }

  .info {
    display: flex;
    justify-content: space-between;

    .title {
      margin-top: 0;
      margin-bottom: 1em;
      font-size: 28px;
      display: flex;
      align-items: center;

      .info-btn {
        margin-left: 1em;
      }
    }
  }

  .total {
    margin-top: 0;
    text-align: right;
    span {
      font-size: 28px;
      display: block;
    }
  }

  .controls {
    display: flex;
    justify-content: center;
  }

  .cart-button {
    display: flex;
    align-items: center;
    margin-top: 1em;
    border: 0;
    font-size: 16px;
    text-align: left;
    padding: 0;
    color: $blue;
    background: transparent;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }

    &.add-sample:before {
      content: "+";
    }

    &.remove-sample:before {
      content: "x";
    }

    &:before {
      content: "+";
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background: $blue;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }

    &.selected {
      color: $darkGrey;
      pointer-events: none;
      &:hover {
        text-decoration: none;
      }
      &:before {
        display: none;
      }
    }
  }
}
