.directory-icd10-item {
  border: 1px solid $greyBorder;
  padding: 16px 31px;
  margin-bottom: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: $bodyFontColor;

  &:hover {
    background: $greyHover;
    cursor: pointer;
  }

  img {
    margin-right: 30px;
  }

  p {
    margin: 0;
  }
  .left {
    display: flex;
  }
  .right {
    align-self: flex-start;
    color: $grey;
  }

  .title {
    font-size: 20px;
  }

  .label {
    margin-top: 0em;
    color: $grey;
  }
}
