.info-btn {
  display: inline-block;
  background: #d6d6d6;
  color: $bodyFontColor;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  line-height: 18px;
  text-transform: lowercase;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  .__react_component_tooltip {
    text-transform: none;
    max-width: 300px;
    font-size: 16px;
    line-height: 130%;
    z-index: 99;
    text-align: left;
    padding: 1em 2em;
    opacity: 1;
    background: rgba(0, 0, 0, 1);
    font-weight: normal;

    &.place-left::after {
      border-left: 0 !important;
    }

    &.place-right::after {
      border-right: 0 !important;
    }

    &.place-top::after {
      border-top: 0 !important;
    }

    &.place-bottom::after {
      border-bottom: 0 !important;
    }
  }
}
