.finbb-btn {
  display: flex;
  font-size: inherit;
  align-items: center;
  padding: 1em 34px;
  text-decoration: none;
  background: $blue;
  line-height: 100%;
  color: #fff;
  border: 0;
  outline: 0;
  margin: 0;
  cursor: pointer;

  &.transparent {
    background: transparent;
    color: #ccc;
  }
}

.button {
  border-radius: 18px;
  background-color: $darkBlue;
  color: white;
  border: 0;
  padding: 5px 20px;
  outline: 0 !important;

  &:hover {
    background-color: lighten($darkBlue, 8%);
  }

  &:active {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.05);
  }

  &.button-secondary {
    background-color: #dddddd;
    color: black;

    &:hover {
      background-color: darken(#dddddd, 5%);
    }
  }

  &.button-block {
    display: block;
    width: 100%;
  }

  &.big {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    width: 100%;
    height: 3rem;
  }

  &.link {
    a {
      color: white;
      text-decoration: none;
      a:link,
      a:visited,
      a:hover,
      a:active {
        text-decoration: none;
      }
    }
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 10px;
  }
}

.tooltip-button {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  font-size: 18px;
  padding: auto;
  font-family: monospace;
  padding: 0px;
  margin-left: 5px;
  font-weight: bold;
  margin-bottom: 8px;
}

.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 600px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px !important;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

span#hide-filters-button {
  button {
    color: #ff0000;
  }
}
