body.body-cart-open {
  overflow-y: hidden;

  .cart-items-holder {
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid $greyBorder;
  }
}

.cart-bar {
  display: flex;
  justify-content: space-between;

  .padder {
    padding: 17px 0;
  }

  .total {
    margin-left: 0.35em;
    font-weight: bold;
  }

  p {
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;

    &.light {
      background: #e4eaf9;
      color: $bodyFontColor;
    }

    &.spaced {
      padding-left: 34px;
      padding-right: 34px;
    }
  }

  .close-cart {
    padding-left: 0;
  }

  button,
  .p {
    display: flex;
    font-size: inherit;
    align-items: center;
    padding: 0 34px;
    text-decoration: none;
    background: $blue;
    line-height: 100%;
    color: #fff;
    border: 0;
    outline: 0;
    margin: 0;
    cursor: pointer;

    &.push {
      margin-left: auto;
    }

    &.light {
      background: #e4eaf9;
      color: $bodyFontColor;
    }

    &.transparent {
      background: transparent;
      color: $bodyFontColor;
    }

    .total {
      display: inline-block;
      margin-left: 0.5em;
    }
  }
}

.cart-bar {
  justify-content: center;
}

.cart-open {
  position: fixed;
  z-index: 10;
  top: 100vh;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: #fff;
  transition: top 0.3s ease-in-out;

  display: flex;
  flex-direction: column;

  &.open {
    top: 0;
  }

  .full-width {
    display: block;
    width: 100%;
    border-bottom: 1px solid $greyBorder;
    box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.04);
  }

  .no-items {
    margin-top: 2em;
    text-align: center;
  }

  .intro {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: $grey;
  }

  .bottom-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 3em;
    padding-bottom: 1em;
    > * {
      margin-left: 1em;
    }
  }
}

.cart-closed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;

  border-top: 1px solid $greyBorder;

  .cart-bar {
    justify-content: center;
  }

  ol {
    list-style: none;
    counter-reset: numbered-counter;
    margin: 0;
    margin-right: 2.5em;

    li {
      display: inline-block;
      margin-right: 2.5em;
      counter-increment: numbered-counter;

      span {
        margin-left: 0.75em;
      }
      &:before {
        content: counter(numbered-counter);
        display: inline-block;
        background: #d6d6d6;
        color: #3e3e3e;
        border-radius: 100%;
        width: 29px;
        height: 29px;
        text-align: center;
        line-height: 29px;
      }
      &.active_step:before {
        background: $blue;
        color: #fff;
      }
    }

    @media (max-width: 900px) {
      display: flex;
      justify-content: flex-start;
      li:not(:first-child) span {
        display: none;
      }
    }
  }
}

.cart-item {
  padding: 4em 0;
  border-bottom: 1px solid #e0e0e0;

  .info,
  .extra {
    display: flex;
    justify-content: space-between;
  }

  .info {
    margin-bottom: 3em;
  }

  h3 {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .total {
    .amount {
      display: block;
      font-size: 36px;
      text-align: right;
      margin-bottom: 0.5em;
    }
    .label {
      color: #878787;
      .info-btn {
        margin-left: 0.5em;
      }
    }
  }

  .specimen-type {
    font-size: 22px;
  }

  .filters {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    > li {
      position: relative;
      display: inline-block;
      border: 1px solid $greyBorder;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 15px 5px 15px;
      // padding-right: 35px;
      margin: 0 1em 0 0 !important;
      color: $darkGrey;
      // &:after {
      //   position: absolute;
      //   right: 13px;
      //   top: 8px;
      //   content: "\0000D7";
      //   margin-left: 1em;
      //   font-size: 150%;
      //   line-height: 100%;
      //   //margin-top: 5px;
      // }
    }
  }

  .remove {
    position: relative;
    cursor: pointer;
    border: 0;
    padding: 0;
    font-size: 16px;
    color: $red;
    display: flex;
    align-items: center;
    background: transparent;
    &:before {
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      content: "\0000D7";
      margin-right: 0.5em;
      font-size: 150%;
      line-height: 100%;
      background: $red;
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 22px;
      font-weight: bold; //margin-top: 5px;
    }
  }
}

.link {
  text-decoration: none;
}
