@import "../../../assets/sass/variables";

.statusBlock {
  width: 21px;
  height: 3px;
  background-color: $lightGrey;
  margin-left: 5px;
}

.green {
  background-color: #6dd82b;
}

.orange {
  background-color: #fdc411;
}

.red {
  background-color: #f43737;
}
